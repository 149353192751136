<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="smsSendPopup"
      header="SMS 전송"
      allowDragging="true"
      showCloseIcon="true"
      isModal="true"
      width="320"
      height="740"
      :enableResize="true"
      :animationSettings="{ effect: 'None' }"
      @close="onSmsSendPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section
                  class="article-section"
                  :style="{
                    height: `calc(100% - ${isSmsSendTypeContent ? '115px' : '91px'})`
                  }"
                >
                  <div class="sms-send-box">
                    <div class="preview-top">
                      <div class="speaker-box" />
                      <div class="speaker-small-box" />
                    </div>
                    <div class="preview-middle">
                      <div class="screen-box">
                        <div class="user-profile">
                          <div class="image">{{ username.substr(0, 1) }}</div>
                          <div class="name">{{ username }}</div>
                        </div>
                        <div class="message-box">
                          [Web발신]
                          <br />
                          <textarea
                            ref="aditWords"
                            placeholder="메시지를 입력해주세요"
                            v-model="smsSendInfo.message"
                            :maxlength="maxSmsByteLength"
                            @keyup="resize"
                            @keydown="resize"
                            @focus="resize"
                            spellcheck="false"
                          />
                        </div>
                        <div
                          class="limit"
                          :style="{
                            color:
                              getByteLength(smsSendInfo.message) >
                              maxSmsByteLength
                                ? 'red'
                                : '#333',
                          }"
                        >
                          {{
                            numberWithCommas(getByteLength(smsSendInfo.message))
                          }}
                          / {{ numberWithCommas(maxSmsByteLength) }} Byte ({{
                            getByteLength(smsSendInfo.message) >= 90
                              ? "LMS"
                              : "SMS"
                          }})
                        </div>
                      </div>
                    </div>
                    <div class="preview-bottom">
                      <div class="button-box" />
                    </div>
                  </div>
                </section>
                <section
                  class="article-section"
                  :style="{
                    height: `${isSmsSendTypeContent ? '115px' : '91px'}`
                  }"
                >
                  <div class="section-body" style="border: none">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li
                              v-if="isSmsSendTypeContent"
                              class="field"
                              style="width: calc(100% + 1px)"
                            >
                              <div class="title required">전송타입</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          v-model="smsSendInfo.type"
                                          :dataSource="searchOptions.smsSendTypeOptions"
                                          :allowFiltering="false"
                                          :fields="commonCodeFields"
                                          cssClass="body-data-dropdown"
                                          @select="onSmsSendTypeSelect"
                                          :readonly="isPageType"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" style="width: calc(100% + 1px)" v-if="!isPageTypeAndRcver">
                              <div class="title required">수신자명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input" style="width: 83%;">
                                    <div class="form">
                                      <input-text
                                        ref="rcverName"
                                        v-model="smsSendInfo.rcverName"
                                        :maxlength="10"
                                        @change="onRcverNameChanged"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group button">
                                    <ul class="button">
                                      <li class="search">
                                        <erp-button
                                            button-div="GET"
                                            v-on:click.native="
                                            onMemberPopupOpen(
                                              true,
                                              memberPopupType.RESV,
                                              smsSendInfo.rcverName,
                                              '',
                                              null
                                            )
                                          "
                                        >
                                          검색
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" style="width: calc(100% + 1px)" v-if="!isPageTypeAndRcver">
                              <!-- 필수 : required -->
                              <div class="title required">수신번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <component-telephone
                                        ref="rcverInfo"
                                        v-model="smsSendInfo.rcverInfo"
                                        placeHolder="핸드폰 뒷 4자리"
                                        :max-length="11"
                                        @change="onSearchByPhoneNumber($event)"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" style="width: calc(100% + 1px)">
                              <!-- 필수 : required -->
                              <div class="title">발신번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="dsptchNo"
                                        v-model="smsSendInfo.dsptchNo"
                                        disabled
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="keyColor">
              <erp-button
                  button-div="SAVE"
                  :ignore="showTestSmsSendPopup"
                  :is-icon-custom="true"
                  :is-custom-shortcut-button="true"
                  shortcut-key="SmsSendPopup.shortcuts.sendSms"
                  :shortcut="{key: 'F7'}"
                @click.native="onSmsSendButtonClicked"
              >
                전송
              </erp-button>
            </li>
            <li>
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="onTestButtonClicked">
                검증
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  @click.native="onInitButtonClicked">
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeSmsSendPopup"> 닫기 </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <test-sms-send-popup
      v-if="showTestSmsSendPopup"
      ref="testSmsSendPopup"
      @smsSend="smsSend"
      @popupClosed="onTestSmsSendPopup"
    />
    <member-select-popup
        v-if="isMemberSelectPopupOpen"
        ref="memberSelectPopup"
        :position="memberPopupPosition"
        :isModal="true"
        @popupClosed="memberSelectPopupClosed"
        @popupConfirmed="memberSelectPopupConfirmed"
    />
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import { getFormattedTelNumber } from "@/utils/string";
import { numberWithCommas } from "@/utils/number";
import { validateFormRefs } from "@/utils/formUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import InputText from "@/components/common/text/InputText";
import { mapGetters } from "vuex";
import {
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
  commonCodesGetCommonCodeByIdxAttrb,
  commonCodesGetStandardInfo
} from "@/utils/commonCodes";
import {getByteLength} from "@/utils/string";
import ErpButton from "@/components/button/ErpButton.vue";
import TestSmsSendPopup from "@/components/popups/TestSmsSendPopup";
import memberSelectPopup from "@/views/common/MemberSelectPopup";
import ComponentTelephone from "@/components/ComponentTelephone";
import moment from "moment";
import {getDayOfWeekCaption} from "@/utils/date";

export default {
  name: "SmsSendPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: { ComponentTelephone, TestSmsSendPopup, InputText, ErpButton, memberSelectPopup },
  data() {
    return {
      smsSendInfo: {
        type: "NOTE",
        message: null,
        bizName: commonCodesGetStandardInfo("bizName"),
        bizNameLocation: commonCodesGetStandardInfo("bizNameLocation"),
        rcverName: null,
        rcverInfo: null,
        dsptchNo: null,
        subject: null,
        sendKey: "0",
        //사용자 SMS 전송 화면에서 호출
        pageType: null,
        sendList: null,

        //조편성 요청 ( GRP_LIST) 에서 필요.
        resveName: null,
        resveTel : null,
        resveCourse : null,
        resveTime : null,
        resveCourseTime : null,
      },
      searchOptions: {
        smsSendTypeOptions: null,
      },
      commonCodeFields: { text: "comName", value: "comCode" },
      smsSendValidationRules: {
        message: {
          required: true,
        },
        rcverName: {
          required: true,
        },
        rcverInfo: {
          required: true,
        },
        dsptchNo: {
          required: true,
        },
      },
      showTestSmsSendPopup: false,
      maxSmsByteLength: 2000,
      memberPopupType: {
        RESV: "RESV", // 예약자
        TRANS: "TRANS", // 위임자
        COMP: "COMP", // 동반자
      },
      isMemberSelectPopupOpen: false, // 유저 선택 팝업 Open 여부
      memberPopupPosition: { x: "center", y: "center" },
      isSmsSendTypeContent: false,
    };
  },
  computed: {
    ...mapGetters(["username"]),
    isPopupOpened() {
      return this.isMemberSelectPopupOpen;
    },
    isPageType(){
      //사용자 SMS 전송 화면에서 호출된경우
      return this.smsSendInfo.pageType === "SmsSendForUser" ;
    },
    isPageTypeAndRcver(){
      //사용자 SMS 전송 화면에서 호출이며 다건 대상자 일경우
      return this.smsSendInfo.pageType === "SmsSendForUser" && Array.isArray(this.smsSendInfo.sendList);
    }
  },
  methods: {
    getByteLength,
    numberWithCommas,
    validateFormRefs,
    async showSmsSendPopup(popupData) {
      if (popupData) {
        this.smsSendInfo.type = popupData.smsSendType;
        this.smsSendInfo.message = popupData.messageContents;
        this.smsSendInfo.rcverName = popupData.rcverName;
        this.smsSendInfo.rcverInfo = popupData.rcverInfo;
        this.smsSendInfo.subject = popupData.subject;
        this.smsSendInfo.sendKey = popupData.sendKey.toString();

        //사용자 SMS 전송 화면에서 호출
        this.smsSendInfo.pageType = popupData.pageType;
        this.smsSendInfo.sendList = popupData.sendList;

        //조편성 요청 ( GRP_LIST) 에서 필요.
        this.smsSendInfo.resveName = popupData.resveName;
        this.smsSendInfo.resveTel = popupData.resveTel;
        this.smsSendInfo.resveCourse = popupData.resveCourse;
        this.smsSendInfo.resveTime = popupData.resveTime;
        this.smsSendInfo.resveCourseTime = popupData.resveCourseTime;
      }

      if (!popupData?.messageContents) {
        this.isSmsSendTypeContent = true;
        this.searchOptions.smsSendTypeOptions = commonCodesGetCommonCodeByIdxAttrb("SMS_SEND_TYPE", 3, "Y" );
      }
      //EDS-8 Roy:attrIdx 값이 있을때  SMS_SEND_TYPE / SMS전송타입 속성 4 번째 값을 사용하여 전송타입을 갖고옴
      if (popupData.attrIdx) {
        this.isSmsSendTypeContent = true;
        this.searchOptions.smsSendTypeOptions = commonCodesGetCommonCodeByIdxAttrb("SMS_SEND_TYPE", popupData.attrIdx, "Y" );
      }
      await this.fetchAllSmsWords();
      await this.$nextTick();
      await this.resize();

      if (this.$refs.aditWords) {
        this.$refs.aditWords.focus();
        this.$refs.aditWords.selectionEnd = 0;
        const container = document.querySelector(".screen-box");
        await this.$nextTick();
        container.scrollTop = 0;
      }
    },
    onSmsSendPopupClosed() {
      this.$emit("popupClosed");
    },
    closeSmsSendPopup() {
      this.$refs.smsSendPopup.hide();
    },
    onRcverNameChanged(args) {
      if (args.event && args.previousValue !== args.value) {
        this.$EventBus.$emit("loaderOn");
        try {
          this.onMemberPopupOpen(
              false,
              this.memberPopupType.RESV,
              args.value,
              null
          );
        } catch (error) {
          console.log("getMemberList.err.===>", error);
        } finally {
          this.$EventBus.$emit("loaderOff");
        }
      }
    },
    onMemberPopupOpen(
        isOpenedWithButton,
        popupType,
        memberNameNo,
        contactTelIdx,
        timeInfoIdx,
        selectedIdx,
        memberDivChanged = false,
        memberGradeChanged = false
    ) {

      this.isMemberSelectPopupOpen = true;

      let memberData = {};
      memberData.nomDataViewFlag = false;
      memberData.memberNameNo = memberNameNo;
      memberData.contactTelIdx = contactTelIdx;
      memberData.isOpenedWithButton = isOpenedWithButton;
      memberData.popupType = popupType;
      memberData.memberDivChanged = memberDivChanged;
      memberData.memberGradeChanged = memberGradeChanged;

      if (timeInfoIdx !== null && timeInfoIdx !== undefined) {
        memberData.timeInfoIdx = timeInfoIdx;
      }
      if (selectedIdx !== null && selectedIdx !== undefined) {
        memberData.selectedIdx = selectedIdx;
      }
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    async memberSelectPopupClosed(data) {
      console.log(JSON.stringify(data, null, 2));
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기

      await this.$nextTick();
      this.$refs.rcverName.focusIn();
    },
    async memberSelectPopupConfirmed(data) {
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기

      if (data.selectedRowData) {
        if (data.selectedRowData.memberName) {
          this.smsSendInfo.rcverName = data.selectedRowData.memberName;
          this.smsSendInfo.rcverInfo = data.selectedRowData.hpNo;
        }
      }
      await this.$nextTick();
      this.$refs.rcverName.focusIn();
    },
    async onSearchByPhoneNumber(event) {
      const lastPhoneNumber = event.target.value.replace("-", "");

      if (lastPhoneNumber.length === 4) {
        this.$EventBus.$emit("loaderOn");
        try {
          if (lastPhoneNumber.trim() === "") {
            this.smsSendInfo.rcverName = "";
            this.smsSendInfo.rcverInfo = "";
            return;
          }

          this.smsSendInfo.rcverName = "";
          this.smsSendInfo.rcverInfo = "";

          this.onMemberPopupOpen(
              false,
              this.memberPopupType.RESV,
              '',
              lastPhoneNumber,
              null
          );
        } catch (error) {
          console.log("getMemberList.err.===>", error);
        } finally {
          this.$EventBus.$emit("loaderOff");
        }
      }
    },
    onInitButtonClicked() {
      this.smsSendInfo.message = null;
      this.smsSendInfo.rcverName = null;
      this.smsSendInfo.rcverInfo = null;
    },
    async onSmsSendButtonClicked() {
      //정합성체크
      if (!this.validateFormRefs(this.smsSendValidationRules)) {
        return;
      }

      if (this.getByteLength(this.smsSendInfo.message) > this.maxSmsByteLength) {
        return this.errorToast(`입력한 전송문자가 ${numberWithCommas(this.maxSmsByteLength)} Byte를 넘었습니다`);
      }

      if (!(await this.confirm("SMS 전송하시겠습니까?"))) {
        return;
      }

      await this.smsSend();
    },
    onTestButtonClicked() {
      if (!this.validateFormRefs(this.smsSendValidationRules)) {
        return;
      }

      if (
        this.getByteLength(this.smsSendInfo.message) > this.maxSmsByteLength
      ) {
        return this.errorToast(
          `입력한 전송문자가 ${numberWithCommas(
            this.maxSmsByteLength
          )} Byte를 넘었습니다`
        );
      }

      this.showTestSmsSendPopup = true;
      this.$nextTick(() => {
        this.$refs.testSmsSendPopup.showPopup();
      });
    },
    async smsSend(contactTel = null) {
      let message = [];
      if (this.smsSendInfo.message) {
        message.push(this.smsSendInfo.message);
      }
      message = message.join("\n\n");

      let smsRequestList = [];
      if (contactTel) {
        const item = this.makeSmsInfoTemplate(message);
        item.key = 0;
        item.receiverNumber = contactTel;
        smsRequestList.push(item);
        this.showTestSmsSendPopup = false;
      } else {
        //사용자 SMS 전송 화면에서 호출이며 다건 대상자 일경우
        if(this.isPageTypeAndRcver){
          this.smsSendInfo.sendList.forEach(item => {
            smsRequestList.push(this.makeSmsInfoTemplate(message, item));
          });
        }else{
          smsRequestList = [this.makeSmsInfoTemplate(message)];
        }
      }
      await GolfErpAPI.sendSMS(smsRequestList);
      this.infoToast(this.$t("sms.popupMessage.success"));
    },
    makeSmsInfoTemplate(message, item=null) {
      return {
        type: this.smsSendInfo.type,
        smsKind: this.smsSendInfo.smsKind || null,
        kakaoFlag: this.smsSendInfo.kakaoFlag || false,
        kakaoKind: this.smsSendInfo.kakaoKind || null,
        kakaoDispatchKey: this.smsSendInfo.kakaoDispatchKey || null,
        kakaoTemplateKey: this.smsSendInfo.kakaoTemplateKey || null,
        key: this.smsSendInfo.sendKey,
        reservedFlag: false,
        reserveDateTime: null,
        //사용자 SMS 전송 화면에서 호출이며 다건 대상자 일경우
        receiverName: item ? item.userName : this.smsSendInfo.rcverName,
        receiverNumber: (item ? item.hpNo : this.smsSendInfo.rcverInfo).replace(/-/gi, ""),

        subject: this.smsSendInfo.subject,
        message,
        replaceDataMap: {
          headerMemo: this.smsSendInfo.bizName,
          name: this.smsSendInfo.rcverName,
          resveDate: moment().format('YYYY-MM-DD'), //금일
          dwName: getDayOfWeekCaption(moment().format('YYYY-MM-DD')),
          resveCourseTime: this.smsSendInfo.resveCourseTime,
        },
        dispatchNumber: this.smsSendInfo.dsptchNo.replace(/-/gi, ""),
        sender: "ERP",
      };
    },
    onSmsSendTypeSelect({ itemData }) {
      this.smsSendInfo.type = itemData.comCode;

      //조편성 요청이면 수신자를 예약자로 변경한다.
      //else 의경우 원복하지 않는다. 디폴트로 세팅만 해주는것이고 전화번호 및 이름은 변경 가능하기에 원복안함.
      if(this.smsSendInfo.type === "GRP_LIST"){
        this.smsSendInfo.rcverName = this.smsSendInfo.rcverName || this.smsSendInfo.resveName;
        this.smsSendInfo.rcverInfo = this.smsSendInfo.rcverInfo || this.smsSendInfo.resveTel;
      }

      this.fetchAllSmsWords();
    },
    async fetchAllSmsWords() {
      const allSmsWords = await GolfErpAPI.fetchAllSmsWords();
      const smsSendType = allSmsWords.find(
        (data) => data.smsSendType === this.smsSendInfo.type
      );
      if (smsSendType) {
        this.smsSendInfo.dsptchNo = getFormattedTelNumber(smsSendType.dsptchNo);
        this.smsSendInfo.subject = smsSendType.smsSendTypeName;
        this.smsSendInfo.kakaoFlag = smsSendType.kakaoSendFlag;
        this.smsSendInfo.kakaoDispatchKey = smsSendType.kakaoSendFlag ? commonCodesGetStandardInfo("kakaoDsptchKey") : null;
        this.smsSendInfo.kakaoTemplateKey = smsSendType.kakaoSendFlag ? smsSendType.kakaoTmplatCode : null;
        this.smsSendInfo.smsKind = smsSendType.smsKind;
        if (this.isSmsSendTypeContent) {
          this.smsSendInfo.message = smsSendType.smsWords;
        }
        this.$nextTick(async () => {
          await this.resize();
        });
      } else {
        this.closeSmsSendPopup();
        return this.errorToast("일치하는 SMS전송타입이 존재하지 않습니다");
      }
    },
    onTestSmsSendPopup() {
      this.showTestSmsSendPopup = false;
    },
    async resize() {
      if (this.$refs.aditWords) {
        const container = document.querySelector(".screen-box");
        const saved = container.scrollTop;
        this.$refs.aditWords.style.height = 0;
        this.$refs.aditWords.style.height = `${this.$refs.aditWords?.scrollHeight}px`;
        await this.$nextTick();
        container.scrollTop = saved;
      }
    },
  },
};
</script>
